<template>
    <router-view :class="[$store.state.loading? 'loading' : '']"/>
	<ModalTemplatePreview ref="preview"></ModalTemplatePreview>
	<modal-container ref="modal-container"></modal-container>
	<modal-video ref="modalVideo"></modal-video>
	<modal-notifier ref="notifier"></modal-notifier>
	<HelpPanel></HelpPanel>
	<Outlet ref="outlet"></Outlet>
	<div class="position-absolute bottom-0 start-0 toast-container p-4"></div>
</template>

<script>

import ModalVideo from "@/components/modals/ModalVideo";
import ModalNotifier from "@/components/modals/ModalNotifier";
import ModalTemplatePreview from '@/components/modals/ModalTemplatePreview';
import ModalContainer from "@/components/modals/ModalContainer";
import HelpPanel from "@/components/panels/HelpPanel";
import {validateEmail} from "@/plugins/helper";
import overwrites from "./AppOverwrites";

const $ = window.$;


export default {
	name: "App",
	components: {
		ModalVideo,
		ModalNotifier,
		ModalTemplatePreview,
		ModalContainer,
		HelpPanel
	},

	data() {
		return {
			// oauth: window.$session
		}
	},


    computed: {
        user() {
            return this.$store.getters.user;
        }, 

        isAdmin() {
            let action = false;
            let user = this.$store.getters.user;
            if(user?.is_admin) {
                action = true;
            }

            return action;
        },

		token() {
			return this.$store.getters.token;
		}, 

		links() {
			return {
				DASHBOARD: '/',
				APPS: '/apps',
				WORKBOOKS: '/workbooks',
				RUN: '/run',
				MY_OPPORTUNITIES: '/browse/opportunities',
				MY_STRATEGIES: '/browse/strategies',
				REPORTS: '/reports',
				SETTINGS: '/settings/profile',
				SETTINGS_COMPANY: '/settings/company',
				SETTINGS_PROFILE: '/settings/profile',
				SETTINGS_KEYS: '/settings/keys',
				LIBRARY_STRATEGIES_ADS: '/library/strategies/ads',
				LIBRARY_STRATEGIES_PAGES: '/library/strategies/sales-pages',
				LIBRARY_STRATEGIES_AI: '/library/strategies/ai',
				LIBRARY_STRATEGIES_EMAILS: '/library/strategies/cold-emails',
				LIBRARY_OPPORTUNITIES: '/library/opportunities/all',
				FORMS_STATIC: '/forms_static/company',
				FORMS: '/forms',
				EXTENSIONS: '/extensions',
				ADMIN: '/admin',
				PUBLIC_FORMS: '/public/forms',

				// LIBRARY_STRATEGIES: '/library/strategies/sales-pages',
				// LIBRARY_STRATEGIES_COLD_EMAILS: '/library/strategies/cold-emails',
			}
		}, 


		labels() {
			return {
				DASHBOARD: 'Dashboard',
				OFFERS: 'Products',
				STRATEGIES: 'Marketing'
			}
		},

		routeTitle: {
			set(route) {
				/**
				 * Convert URL section name (slug) into a capitalized text
				 */
				let nameParser = function(name) {
					return name.replace(/-/g, ' ').split(' ').map( (part) => { return (part.substr(0,1).toUpperCase()) + part.substr(1)  }).join(' ');
				};

				let title, name = route.name;

				switch (name) {
					case 'Home':
						title = 'CoreOffer';
						break;

					case 'Strategies':
						title = ['Strategies', nameParser(route.params.kind)];
						break;

					case 'Strategy Details':
						title = ['Strategy Details', nameParser(route.params.kind)];
						break;

					case 'Opportunities':
						title = 'Opportunities';
						break;

					case 'Browse':
						title = ['Browse', nameParser(route.params.section)];
						break;

					case 'Settings':
						title = ['Settings', nameParser(route.params.section)];
						break;

					case 'Report':
						title = ['Report', nameParser(route.params.section)];
						break;

					case 'Admin':
						title = ['Admin', nameParser(route.params.section)];
						break;

					case 'Opportunity Details':
						title = ['Opportunity Details', nameParser(route.params.form)];
						break;

					case 'Forms':
						title = 'Additional Forms';
						break;

					default:
						title = 'CoreOffer';
						break;
				}


				this.updateTitle(title);
			}, 

			get() {
				return document.title;
			}
		}
    },


    watch: {
        user(newVal) {
            if(newVal?.ID) {
				this.$router.push({
					path: '/' + this.$store.state.global.DEFAULT_VIEW
				})
				// let defaultView = '/' + this.$store.state.global.DEFAULT_VIEW;

				// if(window.document.location.pathname != defaultView) {
				// 	window.document.location.href = defaultView;
				// }
            }
        },

        token(newVal) {
            if(!newVal) {
				this.$router.push({
					path: '/' + this.$store.state.global.LOGIN_VIEW
				})
            }
        }, 

		$route(to) {
			this.routeTitle = to;
		}
    },



	methods: {
		updateTitle(term, append=false) {
			let separator = ' › ';
			var parts = typeof term == 'string'? [term] : term;
			let title = parts.join(separator);

			if(append) {
				document.title += separator + title;
			} else {
				document.title = title;
			}
		},


		watchVideo(video) {
			$('#modal-video').data('video', video);
			$('#modal-video').modal('show');
		},


		/**
		 * 
		 * @param {obect} notice 
		 * @param {obect} notice.type valid values: alert, confirm
		 * @param {obect} notice.title
		 * @param {obect} notice.value Default value to show in prompt mode
		 * @param {obect} notice.message
		 * @param {function} notice.callback
		 */
		notify(notice, callback) {

			if( typeof notice == 'string') {
				notice = {
					type: 'message',
					message: notice, 
					callback: callback || function(){}
				}
			}
			/** TODO: find a better way to point to "notifier" reference */
			var notifier = this.$refs.notifier;
				notifier.type = notice.type;
				notifier.title = notice?.title;
				notifier.value = notice?.value;
				notifier.callback = notice.callback;
				notifier.message = notice.message;

			notifier.show();

			return notifier;
		},




		/** 
		 * show a toast message 
		 * 
		 * TODO: move this to a separate component
		 * 
		 * @param {String} message
		 * @param {Object} params
		 * @param {String} params.animation
		 * @param {String} params.autohide
		 * @param {String} params.delay
		 * @param {String} params.type
		 * 
		 */
		toast(message, params) {
			let options = {
				...{
					animation: true,
					autohide: true,
					delay: 4000,
					type: 'dark',
				}, 
				...params
			};

			let html = `
			<div class="toast position-relative bottom-0 start-0 bg-${options.type} mb-1" data-bs-delay="${options.delay}">
				<div class="d-flex">
					<div class="toast-body px-3 fs-6 text-white">
						${message}
					</div>
					<a type="button" class="me-2 m-auto" data-bs-dismiss="toast"
						style="background: none; opacity: 0.5">
						<span class="material-icons text-white-50 pt-1">
						close
						</span>
					</a>
				</div>
			</div>`;

			let el = window.$(html)[0];
				el.addEventListener('hidden.bs.toast', function () {
					el.remove();
					toast.dispose();
					toast = undefined;
				})

			window.$('.toast-container').append(el);

			let toast = new window.bootstrap.Toast(el);
				toast.show();

			return { toast , el };
		},



		reloadOpportunities() {
			this.$store.commit('SET_OPPORTUNITIES', []);
			this.$store.dispatch('fetchOpportunities');
		},


		reloadLibrary() {
			this.$store.commit('SET_OPPORTUNITY_TEMPLATES', []);
			this.$store.dispatch('fetchOpportunityTemplates');
		},



		createOpportunityIntent() {
			let scope = this;
			var modal = this.modal({
				title: 'Create Offer',
				// context: data, 
				// autohide: false,
				okay: {
					label: 'CREATE', 
					async callback(content) {
						scope
						let request = {
							post_title: content.data.title,
							post_excerpt: content.data.excerpt || ""
						};

						let response = await scope.$store.dispatch('createOpportunity', request);
						let path = `/edit/opportunities/${response.name}/business/overview`;

						setTimeout(scope.reloadOpportunities, 500);

						scope.toast(`The offer "${content.data.title}" has been created.`);
						
						scope.notify({
							title: 'Confirm',
							type: 'confirm',
							message: 'Would you like to open this offer (' + response.title + ')?',
							callback(okay) {
								okay && scope.$router.push( {path: path} );
							}
						});
					}
				}, 

				cancel: {
					label: 'CLOSE'
				}
			}, 'ModalContentOpportunity');

			return modal;

		},




		duplicateOpportunityIntent(opportunity) {
			var scope = this;
			this.notify({
				type: 'prompt',
				title: 'Duplicate offer',
				message: `Create a copy of the offer "${opportunity.title}"`,
				value: `Copy of ${opportunity.title}`,
				callback: async (action, value)=> {
					if(action && value?.length) {
						let data = {
							title: value,
							ID: opportunity.ID
						};

						let response = await scope.duplicateOpportunity(data);

						scope.toast(`The offer "${opportunity.title}" has been duplicated as "${response?.title}".`);

						this.reloadOpportunities();

						setTimeout(()=>{
							let path = `/edit/opportunities/${response.name}/business/overview`;
							this.notify({
								title: 'Confirm',
								type: 'confirm',
								message: 'Would you like to open this offer (' + response.title + ')?',
								callback(okay) {
									okay && scope.$router.push( {path: path} );
								}
							})
						}, 500);
					}
				}
			});
		},



		/**
		 * 
		 * @param {Object} request
		 * @param {Object} request.ID
		 * @param {Object} request.title
		 */
		async duplicateOpportunity(data) {

			let request = {
				post_title: data.title,
				ID: data.ID
			};

			let response = await this.$store.getters.wp.run('duplicateExistingOpportunity', [request]);

			return response;
		},







		/**
		 * @param {Object} opportunity The complete opportunity object.
		 *
		 */
		updateOpportunityIntent(opportunity) {
			let scope = this;
			var modal = this.modal({
				title: 'Update Offer',
				// context: data, 
				// autohide: false,
				okay: {
					label: 'UPDATE', 
					async callback(content) {
						scope
						let request = {
							ID: opportunity.ID,
							post_title: content.data.title,
							post_excerpt: content.data.excerpt
						};

						let response = await scope.$store.dispatch('updateOpportunity', request);
						let path = `/edit/opportunities/${response.name}/business/overview`;

						scope.toast(`The offer "${opportunity?.title}" has been updated.`);

						scope.notify({
							title: 'Confirm',
							type: 'confirm',
							message: 'Would you like to open this offer (' + response.title + ')?',
							callback(okay) {
								okay && scope.$router.push( {path: path} );
							}
						});
					}
				}, 

				cancel: {
					label: 'CLOSE'
				}
			}, 'ModalContentOpportunity');

			opportunity;

			setTimeout(()=>{
				let content = modal.content();
				content.data.title = opportunity.title + '';
				content.data.excerpt = opportunity.excerpt + '';
			}, 100);

			return modal;
		},



		deleteOpportunityIntent(opportunity) {
			return new Promise( (resolve)=> {
				this.notify({
					type: 'confirm',
					message: `Are you sure you want to delete this offer (${opportunity.title})?`,
					callback: async (doDeleteOpportunity)=> {
						if(doDeleteOpportunity) {
							/* confirm if linked strategies should be removed */ 
							this.notify({
								type: 'confirm',
								message: `Would you like to delete the strategies linked to this offer?`,
								callback: async (doDeleteStrategies)=> {
									/* delete linked strategies */
									let opportunityId = opportunity.ID;
									if(doDeleteStrategies) {
										await this.$store.dispatch('deleteStrategiesByOpportunityId', opportunityId);
										this.toast(`"${opportunity.title}" strategies have been deleted.`);

									} else {
										await this.$store.dispatch('unlinkStrategiesFromOpportunityId', opportunityId);
										this.toast(`"${opportunity.title}" strategies have been unlinked.`);
									}

									/** delete opportunity */
									await this.$store.dispatch('deleteOpportunity', opportunity);
									this.toast(`The offer "${opportunity.title}" has been deleted.`);

									setTimeout( ()=> {
										this.reloadOpportunities();
										resolve(true);
									}, 500);
								}
							});
						} else {
							resolve(false);
						}
					}
				});
			});


		},



		createOpportunityTemplateIntent(opportunity) {
			this.notify({
				type: 'prompt',
				title: 'Create offer template',
				message: 'What would you like to call this template?',
				value: `${opportunity.title}`,
				callback: (action, value)=> {
					if(action && value?.length) {
						let eventType = this.$store.getters.eventBus.events.OPPORTUNITY_TEMPLATE_CREATED;

						this.$store.getters.eventBus.one(eventType, (event, response) => {
							event;
							this.reloadLibrary();

							this.toast(`The offer template has been created.`);

							setTimeout(()=>{
								this.notify({
									title: 'Yay!',
									type: 'message',
									message: 'The offer template "' + response.title + '" has been created.'
								})
							}, 500);
						});

						let request = {
							post_title: value,
							ID: opportunity.ID
						};
						this.$store.dispatch('createOpportunityTemplate', request);
					}
				}
			});
		},



		downloadOpportunityIntent(opportunity) {
			this.downloadOpportunity(opportunity);
		},



		async downloadOpportunity(opportunity) {
			this.$store.commit('SET_LOADING_STATE', true);
			let data = await this.$store.getters.wp.downloadOpportunity(opportunity.ID);
			let content = await this.$store.dispatch('objectToAppc', data);

			this.download({
				content: content,
				fileName: opportunity.name + '.appc',
				mime: 'text/plain',
				callback: null
			});
			this.$store.commit('SET_LOADING_STATE', false);

		},


		download(params) {
			let defaults = {
				content: 'ERROR',
				fileName: 'file.txt',
				mime: 'text/plain',
				callback: null
			};

			params = {...defaults, ...params};

			let blob = new Blob([params.content]);
			let a = document.createElement('a');
				a.download = params.fileName;
				a.type = params.mime;
				a.href = URL.createObjectURL(blob);
			a.click();
			
			typeof params.callback == 'function' && params.callback.apply(null, [params]);
		},




		async uploadOpportunityIntent() {
			var scope = this;
			let data = {};

			var modal = this.modal({
				title: 'Upload file',
				context: data, 
				autohide: false,
				okay: {
					label: 'UPLOAD', 
					async callback(uploader) {
						scope
						let request = {
							post_title: uploader.opportunity.post_title,
							post_content: {...uploader.opportunity.post_content},
							post_excerpt: uploader.opportunity.post_excerpt
						};

						await scope.$store.getters.wp.createOpportunity(request);
						
						modal.hide();
						uploader.reset();
						scope.reloadOpportunities();
					}
				}, 

				cancel: {
					label: 'CANCEL', 
					callback(uploader) {
						uploader.reset();
						modal.hide();
					}
				}
			}, 'UploadOpportunity');
		},



		grabFile(options) {
			options = {...{
				title: 'Upload',
				message: 'Choose the file you wish to upload.',
				buttonLabel: 'CHOOSE FILE',
				noFileLabel: 'No file chosen',
				okay: {
					label: 'UPLOAD',
					callback: (uploader)=>{
						uploader.reset();
					}
				},
				cancel: {
					label: 'CANCEL',
					callback: (uploader)=>{
						uploader.reset();
					}
				}
			}, ...options};


			var modal = this.modal({
				title: options.title,
				autohide: false,
				context: options, 
				okay: options.okay, 
				cancel: options.cancel,
			}, 'ModalContentUploader');


			setTimeout(()=>{
				modal.content().setup(options);
			}, 0);

			return modal;
		},



		/**
		 * TODO: stop relying on title suffixes
		 * 
		 */
        prependSuffix(title, kind) {
            switch (kind) {
                case 'ads':
                    title = 'Ads: ' + title;
                    break;

                case 'cold-emails':
                    title = 'Cold Emails: ' + title;
                    break;

                case 'sales-pages':
                    title = 'Sales Pages: ' + title;
                    break;

                case 'ai':
                    title = 'Ai: ' + title;
                    break;

                default:
                    break;
            }

            return title;
        },



		/**
		 * 
		 * @param {Object} data 
		 * @param {Object} data.title 
		 * @param {Object} data.buttons 
		 * @param {Object} data.buttons.okay 
		 * @param {String} data.buttons.okay.label 
		 * @param {Boolean} data.buttons.okay.visible 
		 * @param {Boolean} data.buttons.okay.disabled 
		 * @param {Function} data.buttons.okay.callback 
		 * 
		 * 
		 */
        prepareStrategyData(data) {
            let okay, cancel, context, title = data?.title || 'Strategy';

			let buttons = {
				okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
					/**
					 * component.data
					 * component.data.title
					 * component.data.excerpt
					 * component.data.opportunity_id
					 * 
					 */
					callback: async (component) => {
						console.warn('WARNING: no callback has been defined.', component)
                        // typeof callback == 'function' && callback.apply(null, [component]);
					}
				}, 

                cancel: {
                    label: 'CANCEL',
                    visible: true,
                    disabled: false
                }
			};

			if(data?.buttons?.okay) {
				okay = {...buttons.okay, ...data.buttons.okay};
			} else {
				okay = buttons.okay;
			}


			if(data?.buttons?.cancel) {
				cancel = {...buttons.cancel, ...data.buttons.cancel};
			} else {
				cancel = buttons.cancel;
			}


			if(data?.context) {
				context = data.context;
			} else {
				context = {};
			}

            let modal = this.$root.modal({
                title: title,
                context: context,
                okay: okay,
                cancel: cancel,

            }, 'CreateStrategyContent');


			return modal;
        }, 



		/**
		 * 
		 * @param {Object} options
		 * @param {String} options.suffix
		 */
        createStrategyIntent(options) {

			options = options || {
				suffix: 'ai'
			};


            let scope = this;
            let modal = this.prepareStrategyData(
                {
                    title: 'Create strategy',
                    buttons: {
                        okay: {
                            callback: (component) => {
                                /**
                                 * component.data
                                 * component.data.title
                                 * component.data.excerpt
                                 * component.data.opportunity_id
                                 * component.data.opportunity_name
                                 * component.data.opportunity_title
                                 * 
                                 */
                                let context = {...component.data, ...{
                                        /**
                                         * Overwrite title to add suffix 
                                         * 
                                         * TODO: strategy types must be a category or a tag not a "title suffix"
                                         */
                                        title: (options?.suffix? options.suffix + ': ' + component.data.title : component.data.title)
                                    }
                                };

								/** Open AI Strategy Selector */
                                setTimeout( async ()=>{

									let usecase, selection, opportunity = {
										opportunity_id: context.opportunity_id,
										opportunity_name: context.opportunity_name,
										opportunity_title: context.opportunity_title
									};

									console.log('context', context);
									console.log('opportunity', opportunity);

									usecase = await scope.AIChooseStrategy();

									console.log('usecase', usecase);

									/** Open AI Content Generator */
									setTimeout( async () => {
										selection = await scope.AIGenerateStrategy(usecase, opportunity);

										let request = {
											post_title: context.title,
											post_excerpt: context.excerpt,
											post_content: selection.text,
											meta_input: opportunity
										};
										
										scope.$store.commit('SET_LOADING_STATE', true);
										let response = await scope.$store.getters.wp.createAIStrategyForOpportunity(request);
										await scope.$store.dispatch('reloadStrategies');
										scope.$store.commit('SET_LOADING_STATE', false);

										if(response?.ID) {
											scope.openStrategy(response /** strategy data */);
										}

										// /**
										//  * @param {Object} selction
										//  * @param {Object} selction.text
										//  * 
										//  * @param {Object} usecase
										//  * @param {Object} usecase.name
										//  * @param {Object} usecase.slug
										//  * @param {Object} usecase.description
										//  */
										// scope.AIChooseStrategy( async function(selection, usecase) {
										// 	scope, selection, usecase;

										// });


									}, 500);

									// console.log('selection', selection);
									
									

                                }, 500);
                            }
                        }
                    }
                }
            );


            modal;
        },






        /**
         * Edit Strategy details
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        editStrategyDetails(data) {
            let modal = this.$root.modal({
                title: 'Update details',
                context: data,
                okay: {
                    label: 'UPDATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let opportunityData, opportunity = {
                            opportunity_id: "",
                            opportunity_name: "",
                            opportunity_title: ""
                        };

                        if(component.data?.opportunity_id) {
                            opportunityData = await this.$store.getters.wp.getOpportunity(component.data?.opportunity_id);
                            opportunity.opportunity_id = opportunityData.ID;
                            opportunity.opportunity_name = opportunityData.name;
                            opportunity.opportunity_title = opportunityData.title;
                        }
                        
                        let request = {
                            ID: data.ID,
                            post_title: this.prependSuffix(component.data.title, data.kind),
                            post_excerpt: component.data.excerpt,
                            meta: opportunity
                        };

                        this.$store.commit('SET_LOADING_STATE', true);
                        await this.$store.getters.wp.updateStrategyData(request);
                        await this.$store.dispatch('reloadStrategies');
                        this.$store.commit('SET_LOADING_STATE', false);


						this.toast(`The strategy "${component.data.title}" has been edited.`);


						/**
						 * IMPORTANT: in some case the application may need to be notified of when
						 * an strategy has been updated. The bootstrap modal can be used trigger a
						 * "done" event (via jQuery)
						 */
						let $modal = modal.$el;
						window.$($modal).trigger('done', [request]);


						/**
						 * IMPORTANT: if the strategy has been loaded already, the data is cached
						 * in memory. Opening the strategy will not make a new request, therefore
						 * the data will not show the changed from editStrategyDetails(). To force
						 * reload, simply delete the cached data.
						 */
						delete(this.$store.state.active.strategies?.[data.name]);
                    }
                }, 

            }, 'CreateStrategyContent');

            setTimeout(() => {
				/**
				 * Setup opportunity data as copy to prevent Vue reactivity from
				 * editing data in $store.state as user types-in in form
				 */
				let content = modal.content();
                content.data.title = data.title;
                content.data.excerpt = data.excerpt;
                content.data.opportunity_id = data.opportunity_id;
				content.validate = function() {
					let isValid = false;
					if('title' in this.data && this.data.title?.length > 0) {
						isValid = true;
					}
					if(!isValid) {
						alert('Please fill out all required fields');
					}
					return isValid;
				};


				/**
				 * NOTE: once the details have been edited, if the opportunity to which the strategy
				 * belong has been modified, the current strategy must be reloaded to update the UI 
				 * (breadcrumb bar). An easy way to do this is to listen to the Bootrap modal 
				 * 'hidden.bs.modal' event to refreshe the current strategy. 
				 * 
				 * The only caveat is that if there are no chnages in the details, the call will be 
				 * made anyway. Refreshin the current strategy when is has not changed has no impact
				 * in the UI. This solution is preffered over more complicated solutions.
				 * 
				 * Allow time for the DOM to be process so you can get a pointer to the modal element
				 */
                let $modal = modal.$el;

                /**
                 * listen to the done event, fired by the modal from within the editStrategyDetails()
                 * method (once it is done submitting the update requets)
                 */
                window.$($modal).one('done', ()=> {

                    /**
                     * Allow time to make the data available (force the refresh request to run outside
                     * of the original call stack).
                     */
                    setTimeout(async ()=>{
                        await this.$store.dispatch('fetchStrategy', data.name);
                    }, 0);
                });

            }, 0);



			return modal;
        },



        /**
         * Duplicate Strategy
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        duplicateStrategy(data) {
			let scope = this;
            let modal = this.$root.modal({
                title: 'Duplicate strategy',
                context: data,
                okay: {
                    label: 'DUPLICATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let opportunityData, opportunity = {
                            opportunity_id: "",
                            opportunity_name: "",
                            opportunity_title: ""
                        };


                        if(component.data?.opportunity_id) {
                            opportunityData = await this.$store.getters.wp.getOpportunity(component.data?.opportunity_id);
                            opportunity.opportunity_id = opportunityData.ID;
                            opportunity.opportunity_name = opportunityData.name;
                            opportunity.opportunity_title = opportunityData.title;
                        }
                        
                        let request = {
                            ID: data.ID,
                            post_title: this.prependSuffix(component.data.title, data.kind),
                            post_excerpt: component.data.excerpt,
                            meta: opportunity
                        };

                        this.$store.commit('SET_LOADING_STATE', true);
                        let response = await this.$store.dispatch('duplicateStrategy', request);
                        this.$store.commit('SET_LOADING_STATE', false);


						this.toast(`The strategy "${data.title}" has been duplicated as "${component.data.title}".`);


						/**
						 * IMPORTANT: in some case the application may need to be notified of when
						 * an strategy has been updated. The bootstrap modal can be used trigger a
						 * "done" event (via jQuery)
						 */
						let $modal = modal.$el;
						window.$($modal).trigger('done', [request]);


						this.notify({
							title: 'Yay!',
							type: 'confirm',
							message: 'The strategy "' + component.data.title + '" has been created. Would you like to open it?',
							callback(ok) {
								if(ok) {
									let path = `/edit/strategies/${opportunity.opportunity_name}/${data.kind}/${response.name}`;
									scope.$router.push( {path: path} );
								}
							}
						})

                    }
                }, 

            }, 'CreateStrategyContent');

            setTimeout(() => {
				/**
				 * Setup opportunity data as copy to prevent Vue reactivity from
				 * editing data in $store.state as user types-in in form
				 */
				let content = modal.content();
                content.data.title = data.title + ' (copy)';
                content.data.excerpt = data.excerpt;
                content.data.opportunity_id = data.opportunity_id;
				content.validate = function() {
					let isValid = false;
					if('title' in this.data && this.data.title?.length > 0) {
						isValid = true;
					}
					if(!isValid) {
						alert('Please fill out all required fields');
					}
					return isValid;
				};
            }, 0);

			return modal;
        },




		openStrategy(strategy) {
            let scope = this;
			let opportunityName = strategy?.opportunity_name || this.$route.params?.opportunity;
            this.$root.notify({
                type: 'confirm',
                title: 'Congratulations',
                message: `Your strategy "${strategy.title}" has been created. Would you like to open it?`,
                callback(okay) {
                    if(okay) {
                        let path = `/edit/strategies/${opportunityName}/${strategy.kind}/${strategy.name}`;
                        scope.$router.push( {path: path} );
                    }
                }
            });
		},


        /**
         * use custom data (text) to create a new strategy of the kind "others"
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
		 * 
		 * 
         * @param {Object} options.suffix
         * @param {Object} options.message
         * @param {Object} options.value
         * 
         */
        importStrategy(data, options) {
			let scope = this;
			data;
			options = {...{
				suffix: 'others', 
				message: 'Type-in your copy (email, ad, post, etc.)',
				value: '',
				title: 'Add Custom Strategy' 
			}, options};

			let title = options.title;

            let modal = this.$root.modal({
                title: title,
				okay: {
					label: 'NEXT',
					visible: true,
					disabled: false,
					callback: async (component) => {
						// this.$store.dispatch('loginAs', component.current.selection.name);
						let context = component.data;
							context.title = options?.suffix? options.suffix + ': ' + context.title : context.title;

						let request = {
							post_title: context.title,
							post_excerpt: context.excerpt,
							meta_input: {
								opportunity_id: context.opportunity_id,
								opportunity_name: context.opportunity_name,
								opportunity_title: context.opportunity_title
							}
						};

						setTimeout(() => {
							scope.notify({
								title: 'Add Your Text',
								message: options.message,
								value: data,
								type: 'textarea',
								callback: async (okay, value) => {
									if(okay) {
										if(value.length > 0 ) {
											
											request.post_content = value;

											scope.$store.commit('SET_LOADING_STATE', true);
											let response = await scope.$store.getters.wp.createAIStrategyForOpportunity(request);
											await scope.$store.dispatch('reloadStrategies');
											scope.$store.commit('SET_LOADING_STATE', false);
											console.log(request, response);
											this.toast(`Your custom strategy has been added.`);


											if(response?.ID) {
												setTimeout( () => {
													scope.openStrategy(response /** strategy data */);
												}, 500);
											}
										}
									}
								}
							});
						}, 500);
					}
				}, 

            }, 'CreateStrategyContent');

            setTimeout(() => {
				let content = modal.content();
					content.validate = function() {
						return true;
					};
			}, 0);

			modal

			/*
			*/
		},






        /**
         * Delete Strategy
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        deleteStrategy(data) {

			return new Promise( (resolve)=> {
				this.notify({
					title: 'Delete Strategy',
					type: 'confirm',
					message: `Are you sure you want to delete the strategy named <b>${data.title}</b>?`,
					callback: async (okay)=> {
						if(okay) {
							await this.$store.dispatch('deleteStrategy', data);
							this.toast(`The strategy "${data.title}" has been deleted.`);
							resolve(true);
						} else {
							resolve(false);
						}
					}
				});
			});
		},



        /**
         * Create a template from strategy
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
		createTemplateFromStrategy(data) {
			// console.log('createTemplateFromStrategy(...)', data);

			this.notify({
				type: 'prompt',
				title: 'Create strategy template',
				message: 'What would you like to call this template?',
				value: `${data.title}`,
				callback: async (ok, value)=> {
					if(ok && value?.length) {
						let request = {
							post_title: this.prependSuffix(value, data.kind),
							ID: data.ID
						};

						// console.log('createTemplateFromStrategy:request', data);


						let response = await this.$store.dispatch('createTemplateFromStrategy', request);

						// console.log('createTemplateFromStrategy:response', data);

						this.notify({
							title: 'Yay!',
							type: 'message',
							message: 'The strategy template "' + response.title + '" has been created.'
						})
					}
				}
			});
		},


		action(name, data) {
			// console.log('*** appc.action() ***', name, data);
			let url, winName;
			switch (name) {
				case 'reloadOpportunities':
					this.reloadOpportunities();
					break;

				case 'openModalOpportunity':
					winName = data.name;
					url = document.location.origin + `/edit/opportunities/${data.name}/business/overview`;
					window.open(url, winName);
					break;

				case 'openOpportunity':
					url = `/edit/opportunities/${data.name}/business/overview`;
					this.$router.push( {path: url} );
					break;
			
				case 'editOpportunity':
					this.updateOpportunityIntent(data);
					break;

			
				case 'deleteOpportunity':
					this.deleteOpportunityIntent(data);
					break;

			
				case 'renameOpportunity':
					this.renameOpportunityIntent(data);
					break;
			
				case 'duplicateOpportunity':
					this.duplicateOpportunityIntent(data);
					break;

			
				case 'downloadOpportunity':
					this.downloadOpportunityIntent(data);
					break;


				case 'uploadOpportunity':
					this.uploadOpportunityIntent(data);
					break;

				case 'createTemplateFromOpportunity':
					this.createOpportunityTemplateIntent(data);
					break;

				case 'openWindow':
					winName = data?.name || 'Window';
					url = typeof data == 'string' ? data : (
							data?.url? data.url : null
						);
					!!url && window.open(url, winName);
					break;

				default:
					break;
			}
		},

		logoutIntent() {
			let message = 'Are you sure you want to logout?';
			this.notify({
				type: 'confirm',
				message: message,
				callback: async (action)=>{
					if(action) {
                        this.$store.commit('SET_LOADING_STATE', true);

						window.$.get('/logout');

						await this.$store.dispatch('signOut');
						this.$session.destroy();

						setTimeout(()=>{
							this.$store.commit('SET_LOADING_STATE', false);
							document.location.href = '/';
						}, 1000);
					}
				}
			});
		}, 



		async loginAs() {

			if(this.$store.getters.user.is_admin) {
				// Administrators have access to all account (must type email to continue)
				let message = 'Enter the email address of the user you wish to login as';
				this.notify({
					title: 'Login As',
					type: 'prompt',
					message: message,
					callback: (okay, value)=>{
						if(okay) {
							this.$store.dispatch('loginAs', value);
						}
					}
				});

			} else {
				// get a list of users that previously authorized the current user to access their accounts
				let accessGroup = await this.$store.dispatch('getAccessGroup');

				if(accessGroup && accessGroup?.has_access_to?.length > 0) {
					// has accesss to one or more accounts
					let items = accessGroup?.has_access_to.map((item)=>{
						return {
							title: item,
							name: item
						}
					});

					let modal = this.$root.modal({
						title: 'Log-in As',
						okay: {
							label: 'SELECT',
							visible: true,
							disabled: false,
							callback: async (component) => {
								this.$store.dispatch('loginAs', component.current.selection.name);
							}
						}, 
					}, 'ModalContentSelector');

					setTimeout(async () => {
						let content = modal.content();
							content.current.selection = null;
							content.data.items = items;
					}, 0);

				} else {
					// do not have access to any account
					this.notify({
						title: 'Oops!',
						type: 'message',
						message: 'You do not have access to other accounts'
					});
				}
			}
		}, 




		async revokeAccessToUser() { 
			// get a list of users that previously authorized the current user to access their accounts
			let accessGroup = await this.$store.dispatch('getAccessGroup');

			if(accessGroup && accessGroup?.gave_access_to?.length > 0) {
				// has accesss to one or more accounts
				let items = accessGroup?.gave_access_to.map((item)=>{
					return {
						title: item,
						name: item
					}
				});

				let modal = this.$root.modal({
					title: 'Revoke Access',
					okay: {
						label: 'REVOKE',
						visible: true,
						disabled: false,
						callback: async (component) => {
							await this.$store.getters.wp.revokeAccessToUser(component.current.selection.name);
							this.toast(`The user "${component.current.selection.name}" no longer has access to your account.`);
						}
					}, 
				}, 'ModalContentSelector');

				setTimeout(async () => {
					let content = modal.content();
						content.current.selection = null;
						content.data.items = items;
				}, 0);

			} else {
				// do not have access to any account
				this.notify({
					title: 'Oops!',
					type: 'message',
					message: 'You have not granted access to anyone yet.'
				});
			}
		},


		async grantAccessToUser() { 
			this.notify({
				title: 'Grant Access',
				message: 'Allow user to access your account (enter the user\'s email address).',
				type: 'prompt',
				callback: async (okay, value) => {
					if(okay) {
						if(value.length > 0 && validateEmail(value.trim())) {
							await this.$store.getters.wp.grantAccessToUser(value.trim());
							this.toast(`The user "${value}" now has access to your account.`);
						} else {
							alert('ERROR: invalid email address.')
						}
					}
				}
			});
		},





		/**
		 * @param {Object} conf
		 * 		{
		 * 			title: 'Test Content',
		 * 			content: content,
		 * 			size: 'sm',
		 * 			okay: {
		 * 				label: 'YES',
		 *				visible: true,
		 *				disabled: false,
		 * 				callback: (contentComponentVue)=> {
		 * 					console.log(contentComponentVue);
		 * 				}
		 * 			}
		 * 		}
		 * @param {String} content The name of the Vue component to be loaded inside 
		 *							the modal, which should be already included in the ModalContainer
		 */
		modal(conf, content) {
			conf = conf || {
				title: 'Modal',
				content: content,
				context: {},
				okay: {
					label: 'YES',
					callback: (processed)=> {
						processed
						// console.log('okay.callback', processed);
					}
				}
			}
			conf.content = content;

			let modal = this.$refs['modal-container'];
			let defaults = JSON.parse( JSON.stringify(modal.defaults) );
			conf = modal.merge(defaults, conf);
			modal.open(conf);
			return modal;
		}, 


		title(value) {
			if(value) {
				document.title = value;
			}
			return document.title;
		}, 


		goto(url) {
			this.$router.push( {path: url} );
		}, 




		/**
		 * Choose an IA strategy
		 *
		 */

		AIChooseStrategy() {
			// let scope = this;
			return new Promise((resolve, reject)=>{
				var modal = this.modal({
					title: 'Choose AI Strategy',
					size: 'xl',
					okay: {
						label: 'CHOOSE', 
						async callback(content) {
							let usecase = content?.current?.item;
							if(usecase) {
								// setTimeout(()=>{
								// 	scope.AIGenerateStrategy(usecase, callback);
								// }, 500);
								resolve(usecase);
							} else {
								reject()
							}
						}
					}, 

					cancel: {
						label: 'CLOSE'
					}
				}, 'ModalContentAIStrategies');

				/**
				 * reset content
				 */
				setTimeout(()=>{
					let content = modal.content();
						content.current.item = null;
						content.current.selection = null;
				}, 100);
				// return modal;
			});
		},


		// AIChooseStrategy(callback) {
		// 	let scope = this;
		// 	var modal = this.modal({
		// 		title: 'Choose AI Strategy',
		// 		size: 'xl',
		// 		okay: {
		// 			label: 'CHOOSE', 
		// 			async callback(content) {
		// 				let usecase = content?.current?.item;
		// 				if(usecase) {
		// 					setTimeout(()=>{
		// 						scope.AIGenerateStrategy(usecase, callback);
		// 					}, 500);
		// 				}
		// 			}
		// 		}, 

		// 		cancel: {
		// 			label: 'CLOSE'
		// 		}
		// 	}, 'ModalContentAIStrategies');

		// 	/**
		// 	 * reset content
		// 	 */
		// 	setTimeout(()=>{
		// 		let content = modal.content();
		// 			content.current.item = null;
		// 			content.current.selection = null;
		// 	}, 100);
		// 	return modal;
		// },




		/**
		 * Generate IA strategy
		 *
		 * @param {Object} usecase
		 * @param {Object} usecase.name
		 * @param {Object} usecase.slug
		 * @param {Object} usecase.description
		 * 
		 * @param {Object} opportunity
		 * @param {Object} opportunity.opportunity_id
		 * @param {Object} opportunity.opportunity_name
		 * @param {Object} opportunity.opportunity_title
		 * 
		 */
		AIGenerateStrategy(usecase, opportunity) {
			return new Promise((resolve, reject)=>{
				let title = usecase.name || 'Generate AI Content';
				var modal = this.modal({
					title: title,
					size: 'xl',
					okay: {
						label: 'USE SELECTION', 
						async callback(content) {
							let selection = content?.current?.selection;
							if(selection) {
								// typeof callback == 'function' && callback.apply(null, [selection, usecase]);
								resolve(selection);
							} else {
								reject();
							}
						}
					}, 

					cancel: {
						label: 'CLOSE'
					}
				}, 'ModalContentAIGenerate');

				/**
				 * reset content
				 */
				setTimeout(()=>{
					let content = modal.content();
						content.current.usecase = usecase;
						content.current.opportunity = {
							opportunity_id: opportunity?.opportunity_id,
							opportunity_name: opportunity?.opportunity_name,
							opportunity_title: opportunity?.opportunity_title
						};
				}, 100);

				// return modal;
			});
		},

		/**
		 * 
		 * 
		 * 
		 */
		async createUserAIFormula(input) {
			let params = {
				post_title: input.title,
				post_excerpt: input.excerpt || null,
				post_content: input.content || {src: ''}
			};

			let response = await this.$store.getters.wp.run('createUserAIFormula', [ params ]);

			return response;
		},



		async getUserAIFormula(id) {
			let response = await this.$store.getters.wp.run('getUserAIFormula', [id]);
			return response;
		},



		async getUserAIFormulas() {
			let response = await this.$store.getters.wp.run('getUserAIFormulas', []);
			return response;
		},


		editUserAIFormula(formula) {
			let scope = this;
			let title = 'Edit Prompt';

			let modal = this.modal({
				title: title,
				size: 'xl',
				okay: {
					label: 'UPDATE', 
					async callback(content) {
						let data = content.current.formula;
						let response = await scope.updateUserAIFormula(data);
						// TODO: handle error if any
						console.log('UPDATE RESPONSE', response);
					}
				}, 

				cancel: {
					label: 'CLOSE'
				}
			}, 'EditUserAIFormulaModalContent');

			setTimeout(()=>{
				let content = modal.content();
				content.current.formula = formula;
			}, 0);
		},

		runUserAIFormula(formula) {
			let scope = this;
			let title = 'Run Prompt';

			let modal = this.modal({
				title: title,
				size: 'xl',
				okay: {
					label: 'USE SELECTION', 
					async callback(content) {
						scope
						let data = content.current;
						let text = data.selection.text;

						console.log('content', data, text);

						setTimeout(()=>{
							scope.importStrategy(text, {suffix : 'others', title: 'AAA'});
						}, 500);
						
					}
				}, 

				cancel: {
					label: 'CLOSE'
				}
			}, 'RunUserAIFormulaModalContent');

			setTimeout(()=>{
				let content = modal.content();
				content.current.formula = formula;
			}, 0);
		},


		async updateUserAIFormula(data) {
			let params = {
				ID: data.ID,
				post_title: data.title,
				post_excerpt: data.excerpt || '',
				post_content: data.content
			};

			let response = await this.$store.getters.wp.run('updateUserAIFormula', [ params ]);

			return response;
		},


		async deleteUserAIFormula(data) {
			console.log('deleteUserAIFormula', data);

		},




																// AIGenerateStrategy(usecase, callback) {
																// 	let title = usecase.name || 'Generate AI Content';
																// 	var modal = this.modal({
																// 		title: title,
																// 		size: 'xl',
																// 		okay: {
																// 			label: 'USE SELECTION', 
																// 			async callback(content) {
																// 				let selection = content?.current?.selection;
																// 				if(selection) {
																// 					typeof callback == 'function' && callback.apply(null, [selection, usecase]);
																// 				}
																// 			}
																// 		}, 

																// 		cancel: {
																// 			label: 'CLOSE'
																// 		}
																// 	}, 'ModalContentAIGenerate');

																// 	/**
																// 	 * reset content
																// 	 */
																// 	setTimeout(()=>{
																// 		let content = modal.content();
																// 			content.current.usecase = usecase;
																// 	}, 100);
																// 	return modal;
																// },



																// async AIGenerate(params, opportunity) {

																// 	alert('ERROR: AIGenerate has been deprecated');

																// 	console.trace('AIGenerate');

																// 	// console.log('*** AIGenerate(...) ***');
																// 	// console.log(params);
																// 	// console.log(opportunity);

																// 	if(!opportunity) {
																// 		params['opportunity_id'] = this.$store.getters.currentOpportunity?.ID;
																// 		params['opportunity_name'] = this.$store.getters.currentOpportunity?.name;
																// 		params['opportunity_title'] = this.$store.getters.currentOpportunity?.title;
																// 	} else {
																// 		params['opportunity_id'] = opportunity?.opportunity_id;
																// 		params['opportunity_name'] = opportunity?.opportunity_name;
																// 		params['opportunity_title'] = opportunity?.opportunity_title;
																// 	}

																// 	let aiSuggestion = await this.$store.getters.wp.run('AIGenerate', [ params ]);

																// 	return aiSuggestion;
																// },



																/**
																 * Uses app.rytr.me unlimited account credentials to generate content
																 * 
																 */
																// async AIGenerateU(usecaseId, inputs, opportunity) {
																// 	alert('ERROR: AIGenerateU has been deprecated');

																// 	console.trace('AIGenerateU');
																// 	// console.log('*** AIGenerate(...) ***');
																// 	// console.log(usecaseId);
																// 	// console.log(inputs);
																// 	// console.log(opportunity);

																// 	if(!opportunity) {
																// 		opportunity = {};
																// 		opportunity['opportunity_id'] = this.$store.getters.currentOpportunity?.ID;
																// 		opportunity['opportunity_name'] = this.$store.getters.currentOpportunity?.name;
																// 		opportunity['opportunity_title'] = this.$store.getters.currentOpportunity?.title;
																// 	}


																// 	let aiSuggestion = await this.$store.getters.wp.run('AIGenerateU', [ usecaseId, inputs,  opportunity ]);

																// 	return aiSuggestion;
																// },



		/**
		 * Genrate AI content using API or KEY
		 * 
		 * IMPORTANT: an opportunity must be selected and its data must be available from <code>app.$store.getters.currentOpportunity</code>
		 * 
		 * @param {Object} inputs
		 * @param {Object} inputs.USECASE When provided, the request must use the API option
		 * @param {Object} inputs.USECASE_ID When provided, the request must use the KEY option
		 * 
         * # Usecases:
		 * 
		 * - blog-idea-outline
		 * - blog-writing
		 * - brand-name
		 * - business-idea-pitch
		 * - business-ideas
		 * - call-to-action
		 * - copywriting-framework-aida
		 * - copywriting-framework-pas
		 * - email
		 * - facebook-twitter-linkedin-ads
		 * - google-search-ads
		 * - interview-questions
		 * - job-description
		 * - website-landing-page
		 * - magic-command
		 * - post-captions
		 * - product-description
		 * - product-description-bullets
		 * - social-media-bio
		 * - question-answer-generator
		 * - reply-response-generator
		 * - seo-description
		 * - seo-title
		 * - sms-notifications
		 * - song-lyrics
		 * - story-plot
		 * - tagline
		 * - testimonial-review-generator
		 * - ai-text-completer
		 * - text-continue-ryting
		 * - text-expander-and-lengthener
		 * - grammar-checker-and-readability-improver
		 * - paragraph-content
		 * - text-rephraser
		 * - text-summarizer
		 * - video-channel-description
		 * - video-description
		 * - video-ideas
		 * 
		 * 
		 * @param {Object} params.PRIMARY_KEYWORD_LABEL 	(blog-idea-outline)
		 * 
		 * @param {Object} params.SECTION_TOPIC_LABEL 		(blog-writing)
		 * @param {Object} params.SECTION_KEYWORDS_LABEL 	(blog-writing)
		 * 
		 * @param {Object} params.BRAND_DESCRIPTION_LABEL 	(brand-name)
		 * 
		 * @param {Object} params.BUSINESS_IDEA_LABEL 		(business-idea-pitch)
		 * 
		 * @param {Object} params.INTEREST_LABEL 			(business-ideas)
		 * @param {Object} params.SKILLS_LABEL 				(business-ideas)
		 * 
		 * @param {Object} params.DESCRIPTION_LABEL			(call-to-action)
		 * 
		 * @param {Object} params.PRODUCT_OR_BRAND_DESCRIPTION_LABEL	(copywriting-framework-aida)
		 * 
		 * @param {Object} params.PRODUCT_OR_BRAND_DESCRIPTION_LABEL	(copywriting-framework-pas)
		 * 
		 * @param {Object} params.KEY_POINTS_LABEL			(email)
		 * 
		 * @param {Object} params.PRODUCT_NAME_LABEL		(facebook-twitter-linkedin-ads)
		 * @param {Object} params.PRODUCT_DESCRIPTION_LABEL	(facebook-twitter-linkedin-ads)
		 * 
		 * @param {Object} params.PRODUCT_NAME_LABEL		(google-search-ads)
		 * @param {Object} params.PRODUCT_DESCRIPTION_LABEL	(google-search-ads)
		 * @param {Object} params.TARGET_KEYWORD_LABEL		(google-search-ads)
		 * 
		 * @param {Object} params.INTERVIEWEE_BIO_LABEL		(interview-questions)
		 * @param {Object} params.INTERVIEW_CONTEXT_LABEL	(interview-questions)
		 * 
		 * @param {Object} params.JOB_ROLE_LABEL					(job-description)
		 * 
		 * @param {Object} params.WEBSITE_NAME_LABEL					(website-landing-page)
		 * @param {Object} params.ABOUT_WEBSITE_LABEL					(website-landing-page)
		 * @param {Object} params.FEATURES_LABEL					(website-landing-page)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(magic-command)
		 * 
		 * @param {Object} params.POST_TOPIC_LABEL	(post-captions)
		 * 
		 * @param {Object} params.PRODUCT_NAME_LABEL	(product-description)
		 * @param {Object} params.ABOUT_PRODUCT_LABEL	(product-description)
		 * 
		 * @param {Object} params.PRODUCT_NAME_LABEL	(product-description-bullets)
		 * @param {Object} params.PRODUCT_FEATURES_LABEL	(product-description-bullets)
		 * 
		 * @param {Object} params.ABOUT_YOU_LABEL	(social-media-bio)
		 * 
		 * @param {Object} params.TOPIC_DESCRIPTION_LABEL	(question-answer-generator)
		 * 
		 * @param {Object} params.MESSAGE_LABEL	(reply-response-generator)
		 * 
		 * @param {Object} params.PAGE_META_TITLE_LABEL	(seo-description)
		 * 
		 * @param {Object} params.TARGET_KEYWORDS_LABEL	(seo-title)
		 * 
		 * @param {Object} params.CONTEXT_LABEL	(sms-notifications)
		 * 
		 * @param {Object} params.SONG_IDEA_LABEL	(song-lyrics)
		 * 
		 * @param {Object} params.STORY_IDEA_LABEL	(story-plot)
		 * 
		 * @param {Object} params.DESCRIPTION_LABEL	(tagline)
		 * 
		 * @param {Object} params.NAME_LABEL	(testimonial-review-generator)
		 * @param {Object} params.REVIEW_TITLE_LABEL	(testimonial-review-generator)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(ai-text-completer)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(text-continue-ryting)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(text-expander-and-lengthener)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(grammar-checker-and-readability-improver)
		 * 
		 * @param {Object} params.TOPIC_LABEL	(paragraph-content)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(text-rephraser)
		 * 
		 * @param {Object} params.INPUT_TEXT_LABEL	(text-summarizer)
		 * 
		 * @param {Object} params.CHANNEL_PURPOSE_LABEL	(video-channel-description)
		 * 
		 * @param {Object} params.VIDEO_TITLE_LABEL	(video-description)
		 * 
		 * @param {Object} params.KEYWORDS_LABEL	(video-ideas)
		 * 
		 * 
		 * 
		 * 
		 * @param {Object} opportunity (optional)
		 * @param {Object} opportunity.opportunity_id
		 * @param {Object} opportunity.opportunity_name
		 * @param {Object} opportunity.opportunity_title
		 * 
		 * @return {Obbject} aiSuggestion
		 * 					aiSuggestion.success TRUE or FALSE depending on whether or not the request was a success.
		 * 					aiSuggestion.message If success the value is "success", otherwise the value is the description of the error.
		 * 					aiSuggestion.text The AI recommendation. This will not be available is the request resulted in an error.
		 * 
		 * 
		 */
		async generateAIContent(inputs, opportunity) {
			// console.log('*** generateAIContent(...) ***');
			if(!opportunity) {
				opportunity = {};
				opportunity['opportunity_id'] = this.$store.getters.currentOpportunity?.ID;
				opportunity['opportunity_name'] = this.$store.getters.currentOpportunity?.name;
				opportunity['opportunity_title'] = this.$store.getters.currentOpportunity?.title;
			}

			let aiSuggestion = await this.$store.getters.wp.run('generateAIContent', [ inputs,  opportunity]);

			return aiSuggestion;
		},



        AIShowHistory(callback) {
			// let scope = this;
			var modal = this.modal({
				title: 'AI Strategy History',
				size: 'AIHistory',
				okay: {
					label: 'CHOOSE', 
					async callback(content) {
						let selection = content?.current?.selection;
						if(selection) {
							setTimeout(()=>{
								typeof callback == 'function' && callback.apply(null, [selection]);
							}, 500);
						}
					}
				}, 

				cancel: {
					label: 'CLOSE'
				}
			}, 'ModalContentAIHistory');

			/**
			 * reset content
			 */
			setTimeout(()=>{
				let content = modal.content();
					content.current.item = null;
					content.current.selection = null;
			}, 100);


			modal.$el.setAttribute('data-modal', 'AIShowHistory');

			return modal;
		},




		/**
		 * Get AI recommendations for a give opportunity ID
		 * 
		 * @params {Number} opportunity_id
		 * 
		 * @returns {Array}
		 * 
		 */
        async AIGetHistory(opportunity_id) {
			let history = await this.$store.getters.wp.run('AIHistory', [opportunity_id]);
			return history;
		},



		gotoPlans() {
			let scope = this;
			let plansURL = 'https://appcropolis.com/pricing';
			window.open(plansURL, 'plans');

			setTimeout(()=>{
				scope.notify({
					title: 'Message',
					type: 'confirm',
					message: 'Would you like to reload this window?',
					callback(okay) {
						if(okay) {
							document.location.reload()
						}
					}
				});
			}, 1000);
		},



		/**
		 *  Password update methods
		 * 
		 * 
		 * 
		 * 
		 * 
		 * 
		 * 
		 * 
		 * 
		 */

		/**
		 * Open a modal to prompt user for his/her current password
		 */
		updatePasswordIntent() {
            let message = 'Enter your current password.';
            let modal = this.$root.notify({
                title: 'Change Password',
                type: 'password',
                message: message,
                callback: async (okay, value)=>{
                    if(okay) {
						let token = await this.verifyPassword(value);
                        console.log('token', token);
						if(token) {
							this.acquirePassword(token);
						} else {
							this.$root.notify({
								type: 'alert',
								title: 'Oops!',
								message: 'The password you entered is invalid. Try again.',
								callback: (okay)=>{
									if(okay) {
										this.updatePasswordIntent();
									}
								}
							})
						}
                    }
                }
            });

            modal.ui.labels.password.OK = 'CONTINUE';
		}, 


		acquirePassword(token) {
            let message = 'Enter your new password (choose a combination of letters, numbers, and special characters) —6 chrs minimum';
            let modal = this.$root.notify({
                title: 'Create New Password',
                type: 'password',
                message: message,
                callback: async (okay, value)=>{
                    if(okay) {
						let validation = this.validatePassword(value);
						if(validation.isValid == false) {
							// password is invalid, show error
							this.$root.notify({
								type: 'alert',
								title: 'Invlid Password',
								message: validation.message,
								callback: (okay)=>{
									// try again
									if(okay) {
										this.acquirePassword(token)
									}
								}
							})
						} else {
							this.updatePassword(value, token);
						}
                    }
                }
            });

            modal.ui.labels.password.OK = 'UPDATE';
		}, 

		
		/**
		 * Make a request to the server to versify if the provided 
		 * password matches the password of the currently logged user.
		 * The request made to the server will return a token that 
		 * must be sent back when providing a new password.
		 * 
		 * If the token is null or empty, that means that the password
		 * provided does not match the password in the database. 
		 * 
		 * @param {String} value Actual user password.
		 */
		async verifyPassword(value) {
			this.$store.commit('SET_LOADING_STATE', true);
			let token = await this.$root.$store.getters.wp.run('verifyPassword', [value]);
			this.$store.commit('SET_LOADING_STATE', false);
			return token;
		},


		/**
		 * Check new passwrd to make sure is valid. 
		 */
		validatePassword(value) {
			let isValid = true;
			let message = 'ok';
			if(!value || value.length < 6) {
				isValid = false;
				message = 'Please provide a valid password (6 characters min).';
			}
			return {
				isValid,
				message, 
				value
			}
		},

		/**
		 * 
		 */
		async updatePassword(value, token) {
			this.$store.commit('SET_LOADING_STATE', true);
			let response = await this.$root.$store.getters.wp.run('updatePassword', [value, token]);
			this.$store.commit('SET_LOADING_STATE', false);


			let success = response?.success || false;

			if(success) {
				this.$root.notify({
					type: 'message',
					title: 'Yay!',
					message: 'Your password has been updated.'
				});

				this.$root.toast('Your password has been updated.')
			} else {
				this.$root.notify({
					type: 'alert',
					title: 'Error',
					message: response.message
				});
			}

			return response?.success;
		},




		/**
		 * 
		 * 
		 * @see 
		 * https://developer.wordpress.org/reference/functions/wp_set_password/
		 */
		resetPasswordIntent() {

			this.notify({
				title: 'Reset Password',
				message: 'Please enter the email address associated with your account. We will send you instructions on how to reset your password.',
				type: 'prompt',
				callback: async (okay, value) => {
					if(okay) {
						let message;
						let isEmailValid = validateEmail(value.trim());
						if(value.length > 0 && isEmailValid) {
							let success = await this.resetPasswordRequest(value.trim());
							if(success) {
								message = 'A password reset request for the email address <strong>' + value 
										+ '</strong> has been sent. Please check your email for further instructions.';
								this.notify({
									type: 'message',
									title: 'Success',
									message, 
								});
							}
						} else {
							message = !isEmailValid? 'Please provide a valid email address.' : 'We are unable to reset password for the email address ' + value;
							this.notify({
								type: 'alert',
								title: 'Error',
								message, 
							});
						}
					}
				}
			});
		},


		async resetPasswordRequest(email) {
			let response = await this.$store.getters.wp.run('resetPasswordRequest', [email]);

			if(response && response?.success) {
				// DEV ONLY
				/^localhost/.test(document.location.host) && console.log(response?.dev);

				return true;
			} else {
				this.notify({
					type: 'alert',
					title: 'ERROR',
					message: response?.message
				});
				return false;
			}
		},

	},








	created() {
		let wp = this.$store.getters.wp;
			wp.events.on(wp.events.ERROR, (response)=>{
				if('data' in response && response.data == 403) {
					this.$router.push( {path: '/login'} )
				}	
			});

		/**
		 * If PHP session has started and if it has been exposes to Javascript in a object "$session",
		 * the acquire all the data in the PHP session. Expected values: 
		 * 
		 * google_user_email
		 * google_user_first_name
		 * google_user_last_name
		 * google_user_gender
		 * google_user_image
		 * google_auth_url
		 * 
		 * 
		 */
		if(window?.$session) {
			this.$session.attach(window.$session);
			let sessionToken = this.$session.get('token');
			let sessionUser = this.$session.get('user');
			if(sessionToken) {
				this.$store.getters.wp.token(sessionToken);
			}

			if(sessionUser) {
				this.$store.commit('SET_USER', sessionUser);
			}

		} else {

			let redirect_uri = encodeURIComponent(document.location.origin + '/authenticate');

			this.$session.set('token', null);
			this.$session.set('google_user_email', null);
			this.$session.set('google_user_first_name', null);
			this.$session.set('google_user_last_name', null);
			this.$session.set('google_user_gender', null);
			this.$session.set('google_user_image', null);
			this.$session.set('google_auth_url', 'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=online&client_id=31381570039-mqbi4gatl1te41jhc1l7uic3c9rs7t1o.apps.googleusercontent.com&redirect_uri=' + redirect_uri + '&state&scope=email%20profile&approval_prompt=auto');
		}
	},

	async beforeMount() {
		await this.$capabilities.init(()=>{		
			overwrites.apply(this);
		});
	},

	mounted() {
		/localhost/.test(document.location.hostname) && (window.app = this.$root);
	}
}

</script>

<style>
@import "./assets/css/style.css";

.loading * {
	cursor: progress !important;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}







</style>
