<template>
    <div class="app-header border-bottom">
        <nav class="navbar navbar-expand-lg navbar-light bg-white py-4">
            <div class="container-fluid d-flex flex-row px-4">
                <AppLogo></AppLogo>

                <div class="flex-fill"></div>

                <div class="">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item ms-2">
                            <!-- <IconButton class="" icon="help" data-bs-target="#panel-help" data-bs-toggle="offcanvas"></IconButton> -->
                            <ButtonCircle icon="help" data-bs-target="#panel-help" data-bs-toggle="offcanvas"></ButtonCircle>
                        </li>

                        <li class="nav-item ms-2">
                            <IconButton class="" icon="settings" @click.prevent="goto($root.links.SETTINGS)"></IconButton>
                            <!-- <ButtonCircle icon="settings" @click.prevent="goto($root.links.SETTINGS)"></ButtonCircle> -->
                        </li>

                        <li class="nav-item ms-2">
                            <ButtonAppsDropdown></ButtonAppsDropdown>
                        </li>

                        <li class="nav-item ms-2">
                            <ButtonUserDropdown ></ButtonUserDropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
// import AppLogo from "@/components/AppLogo.vue";
import IconButton from "@/components/IconButton.vue";
import ButtonUserDropdown from "@/components/ButtonUserDropdown.vue";
import ButtonAppsDropdown from "@/components/ButtonAppsDropdown.vue";

export default {
    components: {
        // AppLogo,
        IconButton,
        ButtonUserDropdown,
        ButtonAppsDropdown
    },
    name: "HeaderMain",

    props: {},

    computed: {

        user() {
            return this.$store.getters.user;
        },


        // profileImgURL() {
        //     let url = "https://dummyimage.com/36x36/fd7e14/FFFFFF?text=";
        //     if (this.user?.ID != null) {
        //         url += this.user.user_login.toUpperCase().substr(0, 1);
        //     }
        //     return url;
        // },
    },

    methods: {
        showSettings() {
            window.$('#modal-user-settings').modal('show');
        }, 

        goto(url) {
            // let url = '/settings/company';
            this.$router.push( {path: url} );
        }
    }
};
</script>

<style scoped>
/* .app-header .form-inline {
    width: 50%;
}
.app-header .navbar-brand {
    font-size: 24px;
}
.app-header .nav-item i {
    font-size: 20px;
}

input[type="search"] {
    height: auto;
}

.btn-opportunities {
    background-color: #404fff;
    border-color: #404fff;
    margin-top: -3px;
	border-radius: 50px;
    color: #fff !important;
}


.btn-opportunities:hover {
    color: #fff !important;
}

@media (max-width: 767.98px) {
    .app-header .form-inline {
        width: 18%;
    }
    .app-header .navbar-collapse {
        flex-basis: 0;
    }
    .app-header .navbar-nav {
        flex-direction: row;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .app-header .navbar-collapse {
        flex-basis: 0;
    }
    .app-header .navbar-nav {
        flex-direction: row;
    }
} */
</style>
