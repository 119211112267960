import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import session from "@/plugins/session.js";
import preferences from "@/plugins/preferences.js";
import capabilities from "@/plugins/capabilities/index.js";

// Vue.config.productionTip = false;
// Vue.config.devtools = true;

import outlet from "@/plugins/outlet";
import ui from "@/plugins/ui";
import csv from "@/plugins/csv";
import workbook from "@/plugins/workbook/index.js";



/**
 * Set up routers guard to prevent access to restricted pages.
 * 
 * 
 * @see https://forum.vuejs.org/t/accessing-root-instance-data-methods-on-beforeeach-guards/6148
 */
router.beforeEach((to, from, next) => {
    to,
    from,
    next;

    let publicPages = [
        '/login',
        '/reset-password',
        '/authenticate'
    ];

    let isCurrentPagePublic = publicPages.includes(to.path);

    // console.log('publicPages', publicPages);
    // console.log('to', to.path);

    // return;

    // if(!store.getters.token && to.path != '/login') {
    if (!store.getters.token && !isCurrentPagePublic) {
        // console.log('LOGIN_VIEW', store.state.global.LOGIN_VIEW);
        next({ path: '/' + store.state.global.LOGIN_VIEW });
    } else {
        next();
    }






    // if(!store.getters.token) {
    //     next({ path: '/' + store.state.global.LOGIN_VIEW });
    // } else {
    // 	next();
    // }
    // next();

    // let user = (!!localStorage.user && JSON.parse(localStorage.user)) || {ID: null};

    // if(to.path == '/login') {
    // 	router.app.redirect = from.path; 
    // } else {
    // 	router.app.redirect = ''; 
    // }


    // if('meta' in to && 'restricted' in to.meta && to.meta.restricted === true)  {
    // 	if(user.ID != null) {
    // 		next();
    // 	} else {
    // 		next({ path: '/login' });
    // 	}
    // } else {
    // 	next();
    // }
});


createApp(App)
    .use(store)
    .use(router)
    .use(outlet)
    .use(ui)
    .use(csv)
    .use(workbook)
    .use(session, { persist: true })
    .use(preferences)
    .use(capabilities)
    .mount('#app');