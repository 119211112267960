<template>
  <div class="home d-flex flex-column vh-100">
    <HeaderMain></HeaderMain>
    <WelcomeBanner class="flex-fill"></WelcomeBanner>
    <AppFooter></AppFooter>
  </div>
</template>

<script>

import WelcomeBanner from "@/components/WelcomeBanner.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "Home",
  components: {
    WelcomeBanner,
    AppFooter,
  },
};
</script>
