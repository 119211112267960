<template lang="">
    <div class="d-flex flex-row ai-formula border-top cmpEditUserAIFormulaModalContent">
        <!-- Sidebar: Parameters -->
        <div class="params overflow-hidden pe-3 border-end">
            <form @submit.prevent="" class="h-100 form-generator">
                <ul class="nav nav-tabs fw-500 mb-4">
                    <li class="nav-item flex-fill text-center" v-for="(tab, index) in tabs" :key="index">
                        <a class="nav-link" href="#"
                            :class="[tab.name == current.tab? 'active' : '']"
                            @click.prevent="current.tab = tab.name"
                            >{{ tab.title }}</a>
                    </li>
                </ul>
                <!-- Information -->
                <div class="formula-content-details" v-if="current.tab == 'details'">
                    <div class="mb-3">
                        <label for="ai-formula-name" class="form-label">Name</label>
                        <input type="text" class="form-control" id="ai-formula-name" v-model="current.formula.title">
                    </div>

                    <div class="mb-3">
                        <label for="ai-formula-description" class="form-label">Description</label>
                        <textarea class="form-control" rows="3" id="ai-formula-description" v-model="current.formula.excerpt"></textarea>
                    </div>

                </div>

                <!-- Source -->
                <div class="formula-content-source" v-if="current.tab == 'source'">
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Source (Prompt)</label>
                        <textarea class="form-control" rows="21" v-model="current.formula.content.src"></textarea>
                    </div>
                </div>

                <!-- Form -->
                <div class="formula-content-form d-flex flex-column h-100" v-if="current.tab == 'form'">
                    <div class="ai-formula-inputs">
                        <div class="mb-3" v-for="(input, index) in inputs" :key="index">
                            <label :for="`ai-formula-input-`+ input.name" class="form-label">{{ input.name }}</label>
                            <input type="text" class="form-control" :id="`ai-formula-input-`+ input.name" v-model="current.formData[input.name]">
                        </div>
                    </div>

                    <div>
                        <button type="submit"
                            class="btn btn-primary btn-pill btn-generate btn-unlimited py-2 w-100"
                            @click="completions"
                            >
                            <div class="spinner-border spinner-border-sm me-3" role="status"
                                v-if="isLoading">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            
                            Test Formula</button>
                    </div>
                </div>




                <div class="d-flex flex-row alert alert-warning fs-6 mt-4 p-2 text-black-50" role="alert"
                    v-if="lastError.length">
                    <div class="material-icons-outlined me-2 text-warning">
                    warning
                    </div>
                    <div class="flex-fill" v-html="lastError"></div>
                </div>
            </form>
        </div>

        <!-- Results -->
        <div class="results overflow-scroll px-3 flex-fill">
            <!-- <h4 class="fs-5 text-dark py-3">Results {{ results?.length }}</h4> -->
            <h4 class="fs-5 text-dark py-3">{{ current.formula.title }}</h4>

            
            <div v-for="(entry, index) in results" 
                :key="index" 
                :id="`usecase-${index}`"
                class="usecase-entry pt-2 pb-3 px-3 mb-5 border rounded-3">

                <!-- column right -->
                <div>
                    <div class="text-end pt-1 mb-3">
                        <a class="btn btn-light px-1 py-0 me-2 btn-delete-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="remove(entry, 'usecase-' + index, $event)">
                            <span class="material-icons-outlined float-start" :style="{
                                'margin-top': '-2px'
                            }">
                            delete
                            </span>
                        </a>
                        <a class="btn px-3 py-0 btn-select-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="select($event)">
                            <span>
                                SELECT
                            </span>
                        </a>
                        <a class="btn btn-light text-primary px-3 py-0 btn-deselect-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="select(null)">
                            <span>
                                DESELECT
                            </span>
                        </a>
                    </div>

                    <div class="text-content w-100" v-html="entry.text"></div>
                    <sub class="d-none text-end text-secondary pb-1">{{entry.ID}}</sub>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

// import InputCounter from "@/components/forms/fields/InputCounter.vue";

const $ = window.$;

export default {

    name: 'EditUserAIFormulaModalContent',
    components: {
        // InputCounter
    },

    data() {
        return {
            src: '',
            tabs: [
                {name: 'details', title: 'Details'},
                {name: 'source', title: 'Source'},
                {name: 'form', title: 'Form'}
            ],
            current: {
                tab: 'details',
                formula: {
                    ID: '',
                    title: '',
                    name: '',
                    excerpt: '',
                    content: {
                        src: '', 
                        inputs: []
                    }
                }, 
                formData: {}
                // usecase: null,
                // selection: null,
                // /** set from outside the components (e.g. moda.content().current.opportunity = {...}) */
                // opportunity: null,
                // overview: null,
                // html: ''
            },
            results: [],
            lastError: '',
            /**
             * Tracks how many time the user has unsuccessfully
             * tried to get AI content
             */
            attempts: 0,
            /**
             * TODO: Move the switching logid to the store
             * 
             * If user requested AI content too soon for the amount specified, the the request
             * will use the API URL which does not have a time restriction.
             */
            switchAfeter: 2,
            // allowSubmit: false,
            isLoading: false, 
            // useUnlimited: true
        }
    },


    computed: {
        useUnlimited() {
            let unlimited, safe_mode = this.$session.get('safe_mode');
            if(safe_mode && safe_mode === true) {
                unlimited = false; 
            } else {
                unlimited = true;
            }
            return unlimited;
        }, 

        inputs() {
            let inputs = [];
            let formula = this.current.formula.content.src;
            let tokens = formula.match(/{{[^}]*}}/g);

            if(tokens && tokens?.length > 0) {
                tokens.forEach((token)=>{
                    let input = token.replace(/[{}]/g, '');
                    inputs.push({
                        name: input
                    });
                })
            }
            
            (function(scope) {
                scope.current.formula.content.inputs = inputs;
            })(this);

            return inputs;
        }
    },



    methods: {
        select(event) {
            if(!event) {
                this.current.html = '';
                this.current.selection = null;
                $('.usecase-entry').removeClass('active');
                return;
            }

            let target = $(event.currentTarget).attr('target');

            $('.ai-formula .usecase-entry').removeClass('active');
            $('.ai-formula .usecase-entry[id="'+ target +'"]').addClass('active');

            this.current.html = $('.ai-formula .usecase-entry[id="'+ target +'"] .text-content').html();
            this.current.selection = {text: this.current.html};
        },


        async remove(usecase, elId, event) {
            usecase, elId, event

            if(!confirm('Are you sure you want to delete this entry?')) {
                return;
            }

            let deleted = await this.$store.getters.wp.run('deleteAIStrategy', [usecase.ID]);

            if(deleted) {
                for(let i=0; i<this.results.length; i++) {
                    if(this.results[i].ID == usecase.ID) {
                        this.results.splice(i, 1);
                        break;
                    }
                }
                this.$root.toast('Success! AI suggestion was deleted.');

            } else {
                alert('ERROR: unable to delete content.');
            }
        },



        async completions() {
            // this.lastError = '';
            // this.addContent(aiContent);
            this.inProgress(true);

            let params = {ID: this.current.formula.ID};
            let prompt = this.current.formula.content.src;

            if(this.current.formula.content.inputs.length > 0) {
                this.current.formula.content.inputs.forEach((input)=>{
                    let value = document.querySelector('#ai-formula-input-'+ input.name).value;
                    params[input.name] = value;

                    prompt = prompt.replace(RegExp('{{'+ input.name +'}}'), value);
                });
            }


            
            let text = await this.$root.$store.getters.wp.run('testUserAIFromula', [prompt]);

            // let text = String(new Date());

            let content = '<pre>' + JSON.stringify([params, this.current.formula.content.src, prompt, text], null, 4) + '</pre>';
            this.pushContent(text);
            this.inProgress(false);

            console.log(content);

        },




        pushContent(text) {
            this.results.unshift({text: text});
            setTimeout(()=>{
                // scroll the results DIV to the top
                $('.ai-formula .results')[0].scrollTop = 0;

                // call select and pass a fake event object
                let btn = $('.ai-formula .usecase-entry:first .btn-select-strategy');
                this.select({
                    currentTarget: btn
                })
            }, 100);
        },


        /**
         * 
         * 
         * @param {Object} content
         * @param {Object} content.success
         * @param {Object} content.message
         * @param {Object} content.ID
         * @param {Object} content.text
         * 
         */
        addContent(content) {
            this.select(null);

            if(!content?.text) {
                this.showError(content);
                this.attempts += 1;
                return;
            } else {
                // Replace rytr from all responses
                content.text = content.text.replace(/rytr/ig, 'CoreOffer');
            }


            // if(!content.success) {
            //     content.text = 'ERROR: ' + content.text;
            // }
            this.results.unshift(content);
            this.attempts = 0;


            setTimeout(()=>{
                // scroll the results DIV to the top
                $('.ai-formula .results')[0].scrollTop = 0;

                // call select and pass a fake event object
                let btn = $('.ai-formula .usecase-entry:first .btn-select-strategy');
                this.select({
                    currentTarget: btn
                })
            }, 100);
        },


        inProgress(flag) {
            if(flag) {
                $('.btn-generate').addClass('disabled');
                $('.ai-formula').addClass('loading');
                this.isLoading = true;
            } else {
                $('.btn-generate').removeClass('disabled');
                $('.ai-formula').removeClass('loading');
                this.isLoading = false;
            }
        },


        validate() {
            // return (this.current?.html?.length ? true : false);

            return true;
        },

        // validateInput() {
        //     let valid = true;
        //     let inputs = this.current?.usecase?.contextInputs;
            
        //     if(inputs?.length === 0) {
        //         return false;
        //     }

        //     inputs.forEach((item)=>{
        //         let ref = this.$refs[item._id];
        //         if(ref?.getValue()?.length === 0 || ref.hasError()) {
        //             valid = false;
        //         }
        //         // console.log(ref);
        //     });

        //     this.allowSubmit = valid;
        //     return valid;
        // }, 


        showError(content) {
            let error = 'Sorry, and unexpected has happened. Please wait a few seconds and try again.';
            let tooSoon = content.message?.indexOf('Looks like you beat the AI this time') >= 0? true : false;
            if(tooSoon) {
                error = 'Unable to process your request. Please wait a few seconds and try again.';
            }

            this.lastError = error;
        },


        // autofill() {
        //     let inputs = this.current?.usecase?.contextInputs;

        //     for (let index = 0; index < inputs.length; index++) {
        //         let value = '';
        //         let input = inputs[index];
        //         let keyLabel = input.keyLabel;

        //         switch(keyLabel) {
        //             /**
        //              * call to action
        //              * 
        //              * brand name
        //              * 
        //              * Copywriting Framework: AIDA
        //              * Copywriting Framework: PAS
        //              * 
        //              * Facebook, Twitter, LinkedIn Ads
        //              * 
        //              * Landing Page & Website Copies
        //              */
        //             case 'DESCRIPTION_LABEL':
        //             case 'BRAND_DESCRIPTION_LABEL':
        //             case 'PRODUCT_OR_BRAND_DESCRIPTION_LABEL':
        //             case 'PRODUCT_DESCRIPTION_LABEL':
        //             case 'ABOUT_PRODUCT_LABEL':
        //                 value = this.current.overview?.product_description || ''
        //             break;





        //             /**
        //              * blog idea & outline
        //              * 
        //              * Facebook, Twitter, LinkedIn Ads
        //              */
        //             case 'PRIMARY_KEYWORD_LABEL':
        //             case 'TARGET_KEYWORD_LABEL':
        //                 value = this.current.overview?.keywords || ''
        //             break;



        //             /**
        //              * Facebook, Twitter, LinkedIn Ads
        //              */
        //             case 'PRODUCT_NAME_LABEL':
        //                 value = this.current.overview?.product_name || ''
        //             break;

        //             /**
        //              * Landing Page & Website Copies
        //              */
        //             case 'WEBSITE_NAME_LABEL':
        //                 value = this.current.overview?.website_name || ''
        //             break;

        //             /**
        //              * Landing Page & Website Copies
        //              */
        //             case 'ABOUT_WEBSITE_LABEL':
        //                 value = this.current.overview?.website_description || ''
        //             break;

        //             /**
        //              * Product Description (bullet points)
        //              * 
        //              * Landing Page & Website Copies
        //              */
        //             case 'PRODUCT_FEATURES_LABEL':
        //             case 'FEATURES_LABEL':
        //                 value = this.current.overview?.product_features || ''
        //             break;
        //         }

        //         $('.form-generator #' + keyLabel).val(value).keyup();
        //     }


        //     this.validateInput();
        // },

        // async overview() {
        //     let opportunityId = this.$store.getters.currentOpportunity?.ID || this.current?.opportunity?.opportunity_id;
        //     if(!opportunityId) {
        //         return;
        //     }

        //     let opportunityData = await this.$store.getters.wp.getOpportunity(opportunityId);
        //     let overview = opportunityData?.content?.overview?.fields || {};

        //     this.current.overview = overview;
        // },

        async init() {
            // this.validateInput();
            // let inputs = $('.ai-formula .context-input');
            //     inputs.each((i, item)=>{
            //         $(item).on('keyup', ()=>{
            //             // this.validateInput();
            //         });
            //     });
            // let opportunityId = this.$store.getters.currentOpportunity?.ID || this.current?.opportunity?.opportunity_id;
            // this.results = await this.$root.AIGetHistory(opportunityId);
        },

    },

    mounted() {
        setTimeout( async()=> {
            // await this.overview();
            // this.autofill();
            // await this.init();
        }, 500);
        /localhost/.test(document.location.host) && (window.modal = this);
    }
}
</script>

<style>

.ai-formula {
    max-height: 650px;
    min-height: 650px;
    overflow: hidden;
}


.ai-formula .nav-tabs {
    font-size: 18px;
}
.ai-formula label {
    font-size: 16px;
}

.ai-formula .ai-formula-inputs {
    height: 80%;
}

.ai-formula .params {
    width: 400px;
}


.ai-formula .usecase-entry .text-content {
    font-size: 16px;
}


.ai-formulay .usecase-entry .text-content h1, 
.ai-formulay .usecase-entry .text-content h2, 
.ai-formulay .usecase-entry .text-content h3, 
.ai-formulay .usecase-entry .text-content h4, 
.ai-formulay .usecase-entry .text-content h5, 
.ai-formulay .usecase-entry .text-content ul, 
.ai-formulay .usecase-entry .text-content ol, 
.ai-formulay .usecase-entry .text-content li, 
.ai-formulay .usecase-entry .text-content pre, 
.ai-formulay .usecase-entry .text-content p {
    margin-bottom: 0.8rem;
}

.ai-formula .usecase-entry .text-content h1 { font-size: 2em; }
.ai-formula .usecase-entry .text-content h2 { font-size: 1.5em; }
.ai-formula .usecase-entry .text-content h3 { font-size: 1.17em; }
.ai-formula .usecase-entry .text-content h4 { font-size: 1em; }
.ai-formula .usecase-entry .text-content h5 { font-size: 0.83em; }



.ai-formula .results {
    width: calc( 100% - 400px);
}


.ai-formula .usecase-entry {
    transition: all 0.15s;
    background-color: #f5f9ff;
}

.ai-formula .usecase-entry:hover {
    border-color: #aaa;
    box-shadow: 0 4px 8px #404fff2b !important;
}

.ai-formula .usecase-entry.active {
    border-color: #404fff !important;
    background-color: #404fff;
    color: #fff;
}
.ai-formula .usecase-entry .text-content {
    cursor: pointer;
    border: dotted 1px transparent;
    pointer-events: none;
}

.ai-formula .usecase-entry:hover .text-content {
    border-color: rgba(255, 255, 255, 0.2);
}
.ai-formula .usecase-entry:hover .text-content .material-icons-outlined {
    font-size: 28px !important;
}


/* buttons: delete */
.ai-formula .usecase-entry .btn-delete-strategy {
    color: var(--secondary);
}
.ai-formula .usecase-entry .btn-delete-strategy:hover {
    color: var(--danger) !important;
}
/* buttons: select */
.ai-formula .usecase-entry .btn-select-strategy {
    display: inline-block;
    background-color: #dceaff;
}
.ai-formula .usecase-entry .btn-select-strategy:hover {
    color: #fff;
    background-color: #404fff;
}
.ai-formula .usecase-entry.active .btn-select-strategy {
    display: none;
}
/* buttons: deselect */
.ai-formula .usecase-entry .btn-deselect-strategy {
    display: none;
}

.ai-formula .usecase-entry.active .btn-deselect-strategy {
    display: inline-block;
}


.ai-formula .usecase-entry sub {
    opacity: 0;
    transition: opacity 0.2s;
}
.ai-formula .usecase-entry:hover sub {
    opacity: 1;
}

/* .ai-formula .usecase-entry .icon-unchecked {
    color: #ddd;
}
.ai-formula .usecase-entry .icon-checked {
    color: #fff;
}
.ai-formula .usecase-entry:not(.active) .icon-checked,
.ai-formula .usecase-entry .icon-unchecked {
    display: none;
}
.ai-formula .usecase-entry:not(.active) .icon-unchecked,
.ai-formula .usecase-entry.active .icon-checked {
    display: inline-block;
} */
</style>