import {
    createRouter,
    createWebHistory
}
    from 'vue-router'
import Home from '../views/Home.vue'
// import Dashboard from '@/views/explore/ExploreMain.vue'
const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
},


// {
//     path: '/secret',
//     name: 'Secret',
//     component: async() => {
//         let cmp = await
//         import ( /* webpackChunkName: "secret" */ '@/components/AppLogo.vue');
//         return cmp;
//     }
// },



/**
 * reports
 */
{
    path: '/authenticate',
    name: 'Authenticate',
    component: () =>
        import('@/views/authenticate/AuthenticateMain.vue')
},

/**
 * browse sections (opportunities / strategies)
 */
{
    path: '/browse/:section',
    name: 'BrowseMain',
    component: () =>
        import('@/views/explore/BrowseMain.vue'),
    children: [{
        path: '',
        name: 'Browse',
        component: () =>
            import('@/views/explore/BrowseMain.vue'),
        children: [{
            name: 'OpportunityContent',
            path: ':opportunity',
            component: () =>
                import('@/views/explore/BrowseMain.vue'),
            children: [{
                name: 'StrategyContent',
                path: ':strategy',
                component: () =>
                    import('@/views/explore/BrowseMain.vue')
            }]
        }]
    },
    {
        path: 'opportunities',
        name: 'Opportunities',
        component: () =>
            import('@/views/explore/BrowseMain.vue'),
    },
    {
        path: 'strategies',
        name: 'Strategies',
        component: () =>
            import('@/views/explore/BrowseMain.vue')
    }
    ]
},


/**
 * reports
 */

{
    path: '/reports/opportunities/:opportunity/:section',
    name: 'Report',
    component: () =>
        import('@/views/reports/ReportsMain.vue')
},


/* Library: Opportunities / Strategies */
{
    path: '/library/strategies/:kind',
    name: 'Strategies',
    component: () =>
        import('@/views/library-strategies/StrategiesMain.vue')
},
{
    path: '/library/strategies/:kind/:strategy',
    name: 'StrategyDetails',
    component: () =>
        import('@/views/library-strategies/StrategiesMain.vue')
},





/**
 * edit opportunities
 */
{
    path: '/edit/opportunities/:opportunity/:formGroup',
    name: 'Edit Opportunity',
    component: () =>
        import('@/views/opportunity/OpportunityMain.vue')
},
{
    path: '/edit/opportunities/:opportunity/:formGroup/:form',
    name: 'Opportunity Details',
    component: () =>
        import('@/views/opportunity/OpportunityMain.vue'),
},



/**
 * edit strategy
 */
{
    // path: '/edit/strategies/:kind/:strategy',
    path: '/edit/strategies/:opportunity/:kind/:strategy',
    name: 'Strategy Details',
    component: () => import('@/views/library-strategies/StrategyDetails.vue')
},




/**
 * browse opportunities / strategies
 */

{
    path: '/admin',
    name: 'AdminMain',
    component: () =>
        import('@/views/admin/AdminMain.vue')
},


{
    path: '/admin/:section',
    name: 'Admin',
    component: () =>
        import('@/views/admin/AdminMain.vue'),

    meta: {
        title: 'Admin Section'
    }
},




/**
 * Extensions
 */

{
    path: '/extensions',
    name: 'Extensions',
    component: () =>
        import('@/views/extensions/ExtensionsMain.vue')
},


{
    path: '/extensions/:extension',
    name: 'Extension',
    component: () =>
        import('@/views/extensions/ExtensionsMain.vue')
},




// {
// 	path: '/browse',
// 	name: 'ExploreHome',
// 	component: () => import('@/views/explore/ExploreMain.vue')
// }, 







{
    path: '/library/opportunities/:kind',
    name: 'OpportunityLibrary',
    component: () =>
        import('@/views/library-opportunities/OpportunitiesMain.vue'),
    children: [{
        path: '',
        name: 'Opportunities',
        component: () =>
            import('@/views/library-opportunities/OpportunitiesMain.vue')
    },
        // {
        // 	path: 'b2c',
        // 	name: 'Library B2C',
        // 	component: () => import('@/views/library-opportunities/OpportunitiesMain.vue')
        // }, 
        // {
        // 	path: 'b2b',
        // 	name: 'Library B2B',
        // 	component: () => import('@/views/library-opportunities/OpportunitiesMain.vue')
        // }
    ]
},



/* settings */

{
    path: '/settings/:section',
    name: 'Settings',
    component: () =>
        import('@/views/settings/SettingsMain.vue'),
    children: [
        // {
        // 	path: '',
        // 	name: 'Settings',
        // 	component: () => import('@/views/settings/SettingsMain.vue')
        // }, 
        // {
        // 	path: 'user',
        // 	name: 'User',
        // 	component: () => import('@/views/settings/SettingsMain.vue')
        // }, 
        // {
        // 	path: 'company',
        // 	name: 'Company',
        // 	component: () => import('@/views/settings/SettingsMain.vue')
        // }, 
        // {
        // 	path: 'password',
        // 	name: 'Password',
        // 	component: () => import('@/views/settings/SettingsMain.vue')
        // }, 
        // {
        // 	path: 'settings',
        // 	name: 'Settings',
        // 	component: () => import('@/views/settings/SettingsMain.vue')
        // }
    ]
},







/* Forms */

{
    path: '/forms/:form/:field',
    name: 'FormsField',
    component: () =>
        import('@/views/forms/FormsDetails.vue'),
},
{
    path: '/forms/:form',
    name: 'FormsSingle',
    component: () =>
        import('@/views/forms/FormsDetails.vue'),
},
{
    path: '/forms',
    name: 'FormsAll',
    component: () =>
        import('@/views/forms/FormsMain.vue'),
},


{
    path: '/forms_static/:section',
    name: 'FormsStatic',
    component: () =>
        import('@/views/forms/FormsMain.vue'),
},
{
    path: '/forms_static/:section',
    name: 'FormsStaticSection',
    component: () =>
        import('@/views/forms/FormsMain.vue'),
},





/* Apps */


{
    path: '/apps',
    name: 'AppMain',
    component: () =>
        import('@/views/workbook/AppMain.vue'),
},
{
    path: '/apps/run/:app',
    alias: '/apps/run/:app',
    name: 'AppRun',
    component: () =>
        import('@/views/workbook/AppRun.vue'),
},
{
    path: '/apps/run/:app/:workbook/:form',
    alias: '/apps/run/:app/:workbook',
    name: 'AppEntriesRun',
    component: () =>
        import('@/views/workbook/WorkbookRun.vue'),
},
{
    path: '/apps/edit/:app/:workbook',
    alias: '/apps/edit/:app',
    name: 'AppEdit',
    component: () =>
        import('@/views/workbook/AppDetails.vue'),
},




/* Workbook */


{
    path: '/workbooks',
    name: 'WorkbookMain',
    component: () =>
        import('@/views/workbook/WorkbookMain.vue'),
},
{
    path: '/workbooks/run/:workbook/:form',
    alias: '/workbooks/run/:workbook',
    name: 'WorkbookRun',
    component: () =>
        import('@/views/workbook/WorkbookRun.vue'),
},

// {
// 	path: '/workbooks/:workbook/:form',
// 	name: 'WorkbookDetails',
// 	component: () => import('@/views/workbook/WorkbookDetails.vue'),
// }, 
{
    path: '/workbooks/:workbook',
    name: 'WorkbookFormDetails',
    component: () =>
        import('@/views/workbook/WorkbookDetails.vue'),
    children: [
        // {
        // 	path: '/run/:workbook',
        // 	name: 'WorkbookRun',
        // 	component: () => import('@/views/workbook/WorkbookRun.vue'),
        // },
        {
            path: ':form',
            name: 'WorkbookDetails',
            component: () =>
                import('@/views/workbook/WorkbookDetails.vue'),
        }
    ]
},




/** forms */



{
    path: '/public/forms/:form',
    alias: '/public/forms/:form',
    name: 'PublicForms',
    component: () =>
        import('@/views/workbook/AppRun.vue'),
},


/** appcropolis applications */

// {
// 	path: '/a/:workbook',
// 	name: 'Application',
// 	component: () => import('@/views/application/ApplicationMain.vue'),
// }, 




/** authentication */
{
    path: '/login',
    name: 'Login',
    component: () =>
        import('../views/auth/Login.vue')
},
{
    path: '/logout',
    name: 'Logout',
    component: () =>
        import('../views/auth/Logout.vue')
},
{
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
    import('../views/auth/ResetPassword.vue')
},
// {
//   path: '/about',
//   name: 'About',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
// }


{
    path: '/test/ui',
    name: 'UI',
    component: () =>
        import('@/views/test/UI.vue')
},

]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory('configure-admin'),
    routes
})
export
    default router